import React from "react";
import LangContextProvider from '../context/LangContext';
import Components from "../components/components.js";

class StoryblokEntry extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (!state.story || state.story.uuid === props.pageContext.story.uuid) {
      return null;
    }

    return StoryblokEntry.prepareStory(props);
  }

  static prepareStory(props) {
    const story = props.pageContext.story
    // story.content = story.content //JSON.parse(story.content);

    const settings = { ...props.pageContext.globalSettings }
    const centralData = { ...props.pageContext.centralData }
    const mc = props.pageContext.mc
    const slug = props.pageContext.slug
    if (typeof settings.content === 'string') {
      // settings.content = settings.content //JSON.parse(settings.content)
    }

    return { story, settings, centralData, mc, slug }
  }

  constructor(props) {
    super(props);

    this.state = StoryblokEntry.prepareStory(props);
  }

  render() {
    const { settings, centralData, mc, slug , story} = this.state
    if(!story){
      return null;
    }
    const { content, lang } = story

    return (
      <>
        <LangContextProvider lang={lang} slug={slug}>
          {React.createElement(Components(content.component), {
            key: content._uid,
            blok: content,
            settings,
            centralData,
            mc,
            slug,
            story
          })}
        </LangContextProvider>
      </>
    );
  }
}

export default StoryblokEntry;
